import { EgretLoadable } from 'egret'

const SurveyTab = EgretLoadable({
  loader: () => import('./SurveyTab')
})

const testSurvey = EgretLoadable({
  loader: () => import('./testSurvey')
})

const surveyRoutes = [
  {
    path: '/surveys/sampleSurvey',
    component: SurveyTab,
    // auth: authRoles.admin
  },
  {
    path: '/surveys/testSurvey',
    component: testSurvey,
    // auth: authRoles.admin
  },
]

export default surveyRoutes
