import { NO_USER } from '../SFAuthService'
import SFAuthService from '..//SFAuthService'
import { mapSFToForm, reverseMap } from '../sfDataService'

/**
 * Created by Michał Stawski on 13.10.2020.
 */

const mapFields = {
  // Address: {
  //   name: 'address',
  //   type: 'Address'
  // },
  Id: 'id',
  Associated_Organizations__c: 'associatedOrganizations',

//  slautatation,
}
const reverseMapFields = reverseMap(mapFields)

export const saveContact = (values) => {
  let conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Contact').update(values)
}
export const saveContactParsed = (value) => {
  return saveContact(mapSFToForm(reverseMapFields, value))
}
