import sfOauthConfig from './sfAuthConfig'
import jsforce from 'jsforce'
import localStorageService from '../localStorageService'
import axios from 'axios'
import { getUserParsed, saveUserParsed } from './sfData/sfUser'
import { saveAccount } from './sfData/sfAccount'
import React from 'react'
import { saveContactParsed } from './sfData/sfContact'

/**
 * Created by Michał Stawski on 20.05.2020.
 */

export const NO_USER = 'no_user'

class SFAuthService {
  auth
  jsForceOauth
  user = {
    role: 'GRANTEE', // Todo actual role
    token: null,

  }

  constructor () {
    this.init()
  }

  init = () => {
    this.jsForceOauth = new jsforce.OAuth2(sfOauthConfig.oauth2)
  }
  setUser = (user) => {
    console.log('sfAuthService set user', user)
    this.user = user
    localStorageService.setItem('auth_user', user)
  }
  removeUser = () => {
    this.user = null
    localStorage.removeItem('auth_user')
  }

  getConnection = (user) => {
    if (!user) {
      user = this.user
    }
    if (user) {
      console.log('get connection', user)
      let conn = new jsforce.Connection({
        oauth2: sfOauthConfig.oauth2,
        // logLevel: 'DEBUG',
        instanceUrl: sfOauthConfig.instanceUrl,
        accessToken: user.access_token,
        refreshToken: user.refresh_token,
        version: '49.0',
      })
      return conn
    } else {
      return null
    }
  }
  doLogin = (state) => {
    window.location = this.jsForceOauth.getAuthorizationUrl({
      response_type: sfOauthConfig.response_type,
      scope: sfOauthConfig.scope,
      state: JSON.stringify(state),
    })
  }

  parseToken = () => {
    let query = new URLSearchParams(window.location.query)
    // http://localhost:3000/?
    // error=OAUTH_APP_ACCESS_DENIED&
    // error_description=user+is+not+admin+approved+to+access+this+app
    // &state=%7B%22uri%22%3A%22%2F%22%7D

    if (query.error === 'OAUTH_APP_ACCESS_DENIED') {
      console.error(query.error_description)
      return Promise.reject(query.error)
    }
    let params = new URLSearchParams(window.location.hash.slice(1))
    let access_token = params.get('access_token')
    let refresh_token = params.get('refresh_token')
    let state = JSON.parse(params.get('state'))
    let id = params.get('id')
    console.log('id', id, params)

    let userId = id ? id.split('/')[5] : ''
    /*
    https://test.salesforce.com/id/00D4m0000008bIYEAY/0054m000000KIPHAA4

    access_token=0
    instance_url=https%3A%2F%2Fchtc-ctlc--pc.my.salesforce.com
    id=https%3A%2F%2Ftest.salesforce.com%2Fid%2F00D4c0000008cutEAA%2F0054c000000JlQbAAK
    issued_at=1590558555169
    signature=j5AdKFvbTdWxY%2BiFIVvs88t0tRt28tV3Z1Q0FsEGB3s%3D
    sfdc_community_url=https%3A%2F%2Fpc-chtc.cs138.force.com%2FAPIConnectionPortal
    sfdc_community_id=0DB4c00000000OwGAI
    state=%7B%22uri%2/2%3A%22%2F%22%7D
    scope=id+api+web
    token_type=Bearer
    */

    if (access_token) {
      let user = {
        role: 'ADMIN',
        displayName: 'Grantee User',
        photoURL: '/assets/images/face-7.jpg',

        age: 25,
        email: 'granteeuser@gmail.com',
        id,
        userId,
        access_token,
        refresh_token,
      }
      this.setUser(user)
      return this.getUserInfo(user, state)
    } else {
      this.setUser(null)
      return Promise.reject(NO_USER)
    }
  }

  checkOrganization = (sfUsers, { username, displayName }) => {

    if (!sfUsers.associatedOrganizations) {
      let promise

      if (username.indexOf('@chtc.force.com') !== -1) {
        console.log('got old user', username)
        sfUsers.associatedOrganizations = sfUsers.accountId
        promise = saveUserParsed(sfUsers).then(
          () => sfUsers.associatedOrganizations,
        )
      } else {

        console.log('got new user', username)
        promise = saveAccount({
          Name: 'new Organization for ' + displayName,
          // email: sfUsers.email
        }).then((account) => {
          sfUsers.associatedOrganizations = account.id
          console.log('save user org', sfUsers, account)
          return saveUserParsed(sfUsers).then(() => sfUsers.associatedOrganizations)
        })
      }
      return promise.then(associatedOrganizations => saveContactParsed({
        id: sfUsers.contactId,
        associatedOrganizations,
      }))
    } else {
      return Promise.resolve()
    }

  }

  getUserInfo = (user, state) => {

    return Promise.all([
      axios.get(user.id, { params: { access_token: user.access_token } }),
      getUserParsed(user.userId),
    ]).then(([userInfoResponse, userObject]) => {

      let userInfo = userInfoResponse.data
      console.log('get userInfo', userObject.contactId, userObject.associatedOrganizations)
      return this.checkOrganization(userObject,
        { displayName: userInfo.displayName, username: userInfo.username }).then(() => {
        return {
          user: {
            ...user,
            email: userInfo.email,
            displayName: userInfo.display_name,
            firstName: userInfo.first_name,
            lastName: userInfo.last_name,
            photoURL: userInfo.photos.picture,
            thumbnailURL: userInfo.photos.thumbnail,
            userId: userInfo.user_id,
            username: userInfo.username,
            language: userInfo.language,
            userInfo,
            userObject,
          }, state,
        }
      })
    })

  }

  checkLogin = (user) => {
    return this.getUserInfo(user)
  }

  logout = () => {
    let conn = this.getConnection()
    return conn.logout().then((res) => {
      console.log('logged out user', res)
      this.setUser(null)
    })
  }

}

const instance = new SFAuthService()

export default instance
