import '../fake-db'
import '../styles/_app.scss'
import React from 'react'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import EgretTheme from './EgretLayout/EgretTheme/EgretTheme'
import AppContext from './appContext'
import history from 'history.js'

import routes from './RootRoutes'
import { Store } from './redux/Store'
import Auth from './auth/Auth'
import EgretLayout from './EgretLayout/EgretLayout'
import AuthGuard from './auth/AuthGuard'
import { SnackbarProvider } from 'notistack'
import I18nConnectedProvider from '../translation/I18nConnectedProvider'

const App = () => {
  return (
    <AppContext.Provider value={{ routes }}>
      <Provider store={Store}>
        <I18nConnectedProvider>
          <SnackbarProvider maxSnack={3}>
            <EgretTheme>
              <Router history={history}>
                <Auth>
                  <AuthGuard>
                    <EgretLayout/>
                  </AuthGuard>
                </Auth>
              </Router>
            </EgretTheme>
          </SnackbarProvider>
        </I18nConnectedProvider>
      </Provider>
    </AppContext.Provider>
  )
}

export default App
