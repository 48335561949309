/**
 * Created by Michał Stawski on 20.08.2020.
 */
import { mapSFToForm, reverseMap } from '../sfDataService'
import SFAuthService from '..//SFAuthService'
import { NO_USER } from '../SFAuthService'

/*
let i = {
  'attributes': { 'type': 'User', 'url': '/services/data/v42.0/sobjects/User/0054m000000KIPHAA4' },
  'Id': '0054m000000KIPHAA4',
  'Username': 'externaluser1595852970992@company.com',
  'LastName': 'ExternalUser1595852970992',
  'FirstName': null,
  'Name': 'ExternalUser1595852970992',
  'CompanyName': null,
  'Division': null,
  'Department': null,
  'Title': null,
  'Street': null,
  'City': null,
  'State': null,
  'PostalCode': null,
  'Country': null,
  'Latitude': null,
  'Longitude': null,
  'GeocodeAccuracy': null,
  'Address': null,
  'Email': 'apicommunity+2@magnaro.com',
  'EmailPreferencesAutoBcc': true,
  'EmailPreferencesAutoBccStayInTouch': false,
  'EmailPreferencesStayInTouchReminder': true,
  'SenderEmail': null,
  'SenderName': null,
  'Signature': null,
  'StayInTouchSubject': null,
  'StayInTouchSignature': null,
  'StayInTouchNote': null,
  'Phone': null,
  'Fax': null,
  'MobilePhone': null,
  'Alias': '52970992',
  'CommunityNickname': 'ExternalUser1595852970992',
  'BadgeText': 'Partner',
  'IsActive': true,
  'TimeZoneSidKey': 'GMT',
  'LocaleSidKey': 'en_CA',
  'ReceivesInfoEmails': false,
  'ReceivesAdminInfoEmails': false,
  'EmailEncodingKey': 'ISO-8859-1',
  'UserType': 'PowerPartner',
  'LanguageLocaleKey': 'en_US',
  'EmployeeNumber': null,
  'DelegatedApproverId': null,
  'ManagerId': null,
  'LastLoginDate': '2020-08-20T03:48:11.000+0000',
  'CreatedDate': '2020-07-27T12:29:31.000+0000',
  'CreatedById': '0053j00000A5LriAAF',
  'LastModifiedDate': '2020-07-27T12:29:31.000+0000',
  'LastModifiedById': '0053j00000A5LriAAF',
  'SystemModstamp': '2020-08-19T14:54:05.000+0000',
  'OfflineTrialExpirationDate': null,
  'OfflinePdaTrialExpirationDate': null,
  'UserPermissionsMarketingUser': false,
  'UserPermissionsOfflineUser': false,
  'UserPermissionsAvantgoUser': false,
  'UserPermissionsCallCenterAutoLogin': false,
  'UserPermissionsMobileUser': false,
  'UserPermissionsSFContentUser': false,
  'UserPermissionsInteractionUser': false,
  'UserPermissionsSupportUser': false,
  'ForecastEnabled': false,
  'UserPreferencesActivityRemindersPopup': false,
  'UserPreferencesEventRemindersCheckboxDefault': false,
  'UserPreferencesTaskRemindersCheckboxDefault': false,
  'UserPreferencesReminderSoundOff': false,
  'UserPreferencesDisableAllFeedsEmail': false,
  'UserPreferencesDisableFollowersEmail': false,
  'UserPreferencesDisableProfilePostEmail': false,
  'UserPreferencesDisableChangeCommentEmail': false,
  'UserPreferencesDisableLaterCommentEmail': false,
  'UserPreferencesDisProfPostCommentEmail': false,
  'UserPreferencesHideCSNGetChatterMobileTask': false,
  'UserPreferencesDisableMentionsPostEmail': false,
  'UserPreferencesDisMentionsCommentEmail': false,
  'UserPreferencesHideCSNDesktopTask': false,
  'UserPreferencesHideChatterOnboardingSplash': false,
  'UserPreferencesHideSecondChatterOnboardingSplash': false,
  'UserPreferencesDisCommentAfterLikeEmail': false,
  'UserPreferencesDisableLikeEmail': true,
  'UserPreferencesSortFeedByComment': true,
  'UserPreferencesDisableMessageEmail': false,
  'UserPreferencesDisableBookmarkEmail': false,
  'UserPreferencesDisableSharePostEmail': false,
  'UserPreferencesEnableAutoSubForFeeds': false,
  'UserPreferencesDisableFileShareNotificationsForApi': false,
  'UserPreferencesShowTitleToExternalUsers': true,
  'UserPreferencesShowManagerToExternalUsers': false,
  'UserPreferencesShowEmailToExternalUsers': false,
  'UserPreferencesShowWorkPhoneToExternalUsers': false,
  'UserPreferencesShowMobilePhoneToExternalUsers': false,
  'UserPreferencesShowFaxToExternalUsers': false,
  'UserPreferencesShowStreetAddressToExternalUsers': false,
  'UserPreferencesShowCityToExternalUsers': false,
  'UserPreferencesShowStateToExternalUsers': false,
  'UserPreferencesShowPostalCodeToExternalUsers': false,
  'UserPreferencesShowCountryToExternalUsers': false,
  'UserPreferencesShowProfilePicToGuestUsers': false,
  'UserPreferencesShowTitleToGuestUsers': false,
  'UserPreferencesShowCityToGuestUsers': false,
  'UserPreferencesShowStateToGuestUsers': false,
  'UserPreferencesShowPostalCodeToGuestUsers': false,
  'UserPreferencesShowCountryToGuestUsers': false,
  'UserPreferencesHideS1BrowserUI': false,
  'UserPreferencesDisableEndorsementEmail': false,
  'UserPreferencesPathAssistantCollapsed': false,
  'UserPreferencesCacheDiagnostics': false,
  'UserPreferencesShowEmailToGuestUsers': false,
  'UserPreferencesShowManagerToGuestUsers': false,
  'UserPreferencesShowWorkPhoneToGuestUsers': false,
  'UserPreferencesShowMobilePhoneToGuestUsers': false,
  'UserPreferencesShowFaxToGuestUsers': false,
  'UserPreferencesShowStreetAddressToGuestUsers': false,
  'UserPreferencesLightningExperiencePreferred': true,
  'UserPreferencesHideEndUserOnboardingAssistantModal': false,
  'UserPreferencesHideLightningMigrationModal': false,
  'UserPreferencesHideSfxWelcomeMat': true,
  'UserPreferencesHideBiggerPhotoCallout': false,
  'UserPreferencesGlobalNavBarWTShown': false,
  'UserPreferencesGlobalNavGridMenuWTShown': false,
  'UserPreferencesCreateLEXAppsWTShown': false,
  'UserPreferencesFavoritesWTShown': false,
  'UserPreferencesRecordHomeSectionCollapseWTShown': false,
  'UserPreferencesRecordHomeReservedWTShown': false,
  'UserPreferencesFavoritesShowTopFavorites': false,
  'UserPreferencesExcludeMailAppAttachments': false,
  'UserPreferencesSuppressTaskSFXReminders': false,
  'UserPreferencesSuppressEventSFXReminders': false,
  'UserPreferencesPreviewCustomTheme': false,
  'UserPreferencesHasCelebrationBadge': false,
  'ContactId': '0034m000001sfYYAAY',
  'AccountId': '0014m000002ngJIAAY',
  'CallCenterId': null,
  'Extension': null,
  'PortalRole': null,
  'IsPortalEnabled': true,
  'FederationIdentifier': null,
  'AboutMe': null,
  'FullPhotoUrl': 'https://chtc-ctlc--script--c.documentforce.com/profilephoto/005/F',
  'SmallPhotoUrl': 'https://chtc-ctlc--script--c.documentforce.com/profilephoto/005/T',
  'IsExtIndicatorVisible': false,
  'OutOfOfficeMessage': '',
  'MediumPhotoUrl': 'https://chtc-ctlc--script--c.documentforce.com/profilephoto/005/M',
  'DigestFrequency': 'N',
  'DefaultGroupNotificationFrequency': 'N',
  'IsPrmSuperUser': false,
  'LastViewedDate': '2020-08-19T15:07:49.000+0000',
  'LastReferencedDate': '2020-08-19T15:07:49.000+0000',
  'BannerPhotoUrl': '/profilephoto/005/B',
  'SmallBannerPhotoUrl': '/profilephoto/005/D',
  'MediumBannerPhotoUrl': '/profilephoto/005/E',
  'IsProfilePhotoActive': false,
  'FGM_Portal__Portal_Account_Admin__c': false,
  'FGM_Portal__ProfileType__c': 'Organization',
  'FGM_Portal__Return_URL__c': null,
  'FGM_Portal__UserEmail__c': 'apicommunity+2@magnaro.com',
  'FGM_Portal__UserProfile__c': 'Grantee',
  'Language__c': null
}
*/

const mapFields = {
  // Address: {
  //   name: 'address',
  //   type: 'Address'
  // },
  Id: 'id',
  FirstName: 'firstName',
  LastName: 'lastName',
  Phone: 'contactPhone',
  UserName: 'username',
  LanguageLocaleKey: 'language',
  Email: 'email',
  Associated_Organizations__c: 'associatedOrganizations',
  ContactId: 'contactId',
  AccountId: 'accountId',
  // user permissions checked by salesforce are based on membership in public group
  // Allocation Committee -
}
const reverseMapFields = reverseMap(mapFields)


export const getUser = id => {
  let conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('User').retrieve(id)
}
export const getUserParsed = id => {
  return getUser(id).then(user => {
    console.log('getUser', user)
    return mapSFToForm(mapFields, user)
  })
}

export const getNetworkUser = id => {
  let conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  // return conn.sobject('User').retrieve(id)
  return conn.sobject('NetworkMember')
    .find({
      MemberId: id,
    })
    // .select(Object.keys(mapFields).join(', '))
    .select('NetworkId, MemberId, Network.Name')
    .autoFetch(true)
    .then(result => {
      console.log('getNetworkUser', result)
      if (result && result.length === 1) {
        return result[0]
      } else {
        return Promise.reject()
      }
    })
}

export const getNetwork = name => {
  let conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  // return conn.sobject('User').retrieve(id)
  return conn.sobject('Network')
    .find({
      Name: name,
    })
    .select('Id, Name')
    .autoFetch(true)
    .then(result => {
      console.log('getNetworkUser', result)
      if (result && result.length === 1) {
        return result[0]
      } else {
        return Promise.reject()
      }
    })
}

export const saveUser = value => {
  console.log('save User', value)
  let conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  // delete field that can't be edited
  delete value.AccountId
  return conn.sobject('User').update(value)
}

export const describeObjects = () => {
  let conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return Promise.all([
    conn.sobject('Contact').describe(),
    conn.sobject('Account').describe(),
  ]).then(([contact, account]) => {
    console.log('describe Account', account)
    console.log('describe Contact', contact)
  })
}

export const saveUserParsed = value => {
  return saveUser(mapSFToForm(reverseMapFields, value))
}
