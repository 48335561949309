import { EgretLoadable } from '../../../egret'

/**
 * Created by Michał Stawski on 02.08.2020.
 */

const testForm = EgretLoadable({
  loader: () => import('./testForm')
})

const OrganisationDetails = EgretLoadable({
  loader: () => import('./OrganisationDetails')
})

const Budget = EgretLoadable({
  loader: () => import('./Budget')
})

const ReviewApplications = EgretLoadable({
  loader: () => import('./ReviewApplications')
})

const Homepage = EgretLoadable({
  loader: () => import('./GrantsHome')
})

const ApplicationList = EgretLoadable({
  loader: () => import('./ApplicationList')
})

const ValidatedApplicationsList = EgretLoadable({
  loader: () => import('./ValidatedApplicationsList')
})

const ApplicationEdit = EgretLoadable({
  loader: () => import('./ApplicationEdit')
})

const Milestones = EgretLoadable({
  loader: () => import('./Milestones')
})

const Objectives = EgretLoadable({
  loader: () => import('./Objectives')
})

const ProjectDetails = EgretLoadable({
  loader: () => import('./ProjectDetails')
})

const ApplicantInformation = EgretLoadable({
  loader: () => import('./ApplicantInformation')
})

const UploadFiles = EgretLoadable({
  loader: () => import('./UploadFiles')
})

const Contacts = EgretLoadable({
  loader: () => import('./Contacts')
})

const grantsRoutes = [
  {
    path: '/grants/ApplicationList',
    component: ApplicationList
    // auth: authRoles.admin
  },
  {
    path: '/grants/GrantsHome',
    component: Homepage
  },
  {
    path: '/grants/ValidatedApplicationsList',
    component: ValidatedApplicationsList
  },
  {
    path: '/grants/ReviewApplications/:id',
    component: ReviewApplications
  },
  {
    path: '/grants/ReviewApplications',
    component: ReviewApplications,
    exact: true
  },
  {
    path: '/grants/Application/:id',
    component: ApplicationEdit
    // auth: authRoles.admin
  },
  {
    path: '/grants/Application',
    component: ApplicationEdit,
    exact: true
    // auth: authRoles.admin
  },
  {
    path: '/grants/testForm',
    component: testForm
    // auth: authRoles.admin
  },
  {
    path: '/grants/OrganisationDetails',
    component: OrganisationDetails
    // auth: authRoles.admin
  },
  {
    path: '/grants/Budget',
    component: Budget
    // auth: authRoles.admin
  },
  {
    path: '/grants/Milestones',
    component: Milestones
    // auth: authRoles.admin
  },
  {
    path: '/grants/Objectives',
    component: Objectives
    // auth: authRoles.admin
  },
  {
    path: '/grants/ProjectDetails',
    component: ProjectDetails
    // auth: authRoles.admin
  },
  {
    path: '/grants/ApplicantInformation',
    component: ApplicantInformation
    // auth: authRoles.admin
  },
  {
    path: '/grants/UploadFiles',
    component: UploadFiles
    // auth: authRoles.admin
  },
  {
    path: '/grants/Contacts',
    component: Contacts
    // auth: authRoles.admin
  }
]

export default grantsRoutes
