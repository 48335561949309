/**
 * Created by Michał Stawski on 19.08.2020.
 */

export const mapSFToForm = (map, item) => {
  let ret = {}
  let dir = map.direction
  for (let key in map) {
    if (map[key] && typeof map[key] === 'object') {
      if (map[key][dir] && typeof map[key][dir] === 'function') {
        let data = map[key][dir](item)
        ret[map[key].key] = data
      } else {
        let { fieldName, ...newMap } = map[key]
        if (item[key] && typeof item[key] === 'object') {
          ret[fieldName] = mapSFToForm(newMap, item[key])
        } else {
          console.log('types did not match', key, map[key], item[key])
        }
      }
    } else if (item[key]) {
      ret[map[key]] = item[key]
    }
  }
  return ret
}

export const reverseMap = (map) => {
  let ret = {}

  for (let key in map) {
    if (typeof map[key] === 'object') {
      ret[map[key].key] = {
        ...map[key],
        key: key
      }
    } else {
      ret[map[key]] = key
    }
  }
  ret.direction = 'out'
  return ret
}
