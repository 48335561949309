import { mapSFToForm, reverseMap } from '../sfDataService'
import SFAuthService from '../SFAuthService'
import { NO_USER } from '../SFAuthService'

/**
 * Created by Michał Stawski on 22.08.2020.
 */
/*
let example = {
  'attributes': { 'type': 'Account', 'url': '/services/data/v42.0/sobjects/Account/0014m000002uPSNAA2' },
  'Id': '0014m000002uPSNAA2',
  'IsDeleted': false,
  'MasterRecordId': null,
  'Name': 'new Organization for 0054m000000KIPHAA4',
  'Type': null,
  'RecordTypeId': '0123j000001LByjAAG',
  'ParentId': null,
  'BillingStreet': null,
  'BillingCity': null,
  'BillingState': null,
  'BillingPostalCode': null,
  'BillingCountry': null,
  'BillingLatitude': null,
  'BillingLongitude': null,
  'BillingGeocodeAccuracy': null,
  'BillingAddress': null,
  'ShippingStreet': null,
  'ShippingCity': null,
  'ShippingState': null,
  'ShippingPostalCode': null,
  'ShippingCountry': null,
  'ShippingLatitude': null,
  'ShippingLongitude': null,
  'ShippingGeocodeAccuracy': null,
  'ShippingAddress': null,
  'Phone': null,
  'Fax': null,
  'AccountNumber': null,
  'Website': null,
  'PhotoUrl': null,
  'Sic': null,
  'Industry': null,
  'AnnualRevenue': null,
  'NumberOfEmployees': null,
  'Ownership': null,
  'TickerSymbol': null,
  'Description': null,
  'Rating': null,
  'Site': null,
  'OwnerId': '0054m000000KIPHAA4',
  'CreatedDate': '2020-08-27T13:28:44.000+0000',
  'CreatedById': '0054m000000KIPHAA4',
  'LastModifiedDate': '2020-08-27T13:28:44.000+0000',
  'LastModifiedById': '0054m000000KIPHAA4',
  'SystemModstamp': '2020-08-27T13:28:44.000+0000',
  'LastActivityDate': null,
  'LastViewedDate': '2020-08-27T13:28:44.000+0000',
  'LastReferencedDate': '2020-08-27T13:28:44.000+0000',
  'IsPartner': false,
  'Jigsaw': null,
  'JigsawCompanyId': null,
  'AccountSource': null,
  'SicDesc': null,
  'npe01__One2OneContact__c': null,
  'npe01__SYSTEMIsIndividual__c': false,
  'npe01__SYSTEM_AccountType__c': null,
  'FGM_Base__EIN__c': null,
  'FGM_Base__IRS_Letter__c': false,
  'FGM_Portal__Active__c': null,
  'FGM_Portal__CustomerPriority__c': null,
  'npo02__AverageAmount__c': 0,
  'npo02__Best_Gift_Year_Total__c': null,
  'npo02__Best_Gift_Year__c': null,
  'npo02__FirstCloseDate__c': null,
  'npo02__Formal_Greeting__c': null,
  'npo02__HouseholdPhone__c': null,
  'npo02__Informal_Greeting__c': null,
  'npo02__LargestAmount__c': 0,
  'npo02__LastCloseDate__c': null,
  'npo02__LastMembershipAmount__c': 0,
  'npo02__LastMembershipDate__c': null,
  'npo02__LastMembershipLevel__c': null,
  'npo02__LastMembershipOrigin__c': null,
  'npo02__LastOppAmount__c': 0,
  'npo02__MembershipEndDate__c': null,
  'npo02__MembershipJoinDate__c': null,
  'npo02__NumberOfClosedOpps__c': 0,
  'npo02__NumberOfMembershipOpps__c': 0,
  'npo02__OppAmount2YearsAgo__c': 0,
  'npo02__OppAmountLastNDays__c': 0,
  'npo02__OppAmountLastYear__c': 0,
  'npo02__OppAmountThisYear__c': 0,
  'npo02__OppsClosed2YearsAgo__c': 0,
  'npo02__OppsClosedLastNDays__c': 0,
  'npo02__OppsClosedLastYear__c': 0,
  'npo02__OppsClosedThisYear__c': 0,
  'npo02__SYSTEM_CUSTOM_NAMING__c': null,
  'npo02__SmallestAmount__c': 0,
  'npo02__TotalMembershipOppAmount__c': 0,
  'npo02__TotalOppAmount__c': 0,
  'npsp__Number_of_Household_Members__c': null,
  'npsp__Membership_Span__c': null,
  'npsp__Membership_Status__c': null,
  'npsp__Funding_Focus__c': null,
  'npsp__Grantmaker__c': false,
  'npsp__Matching_Gift_Administrator_Name__c': null,
  'npsp__Matching_Gift_Amount_Max__c': null,
  'npsp__Matching_Gift_Amount_Min__c': null,
  'npsp__Matching_Gift_Annual_Employee_Max__c': null,
  'npsp__Matching_Gift_Comments__c': null,
  'npsp__Matching_Gift_Company__c': false,
  'npsp__Matching_Gift_Email__c': null,
  'npsp__Matching_Gift_Info_Updated__c': null,
  'npsp__Matching_Gift_Percent__c': null,
  'npsp__Matching_Gift_Phone__c': null,
  'npsp__Matching_Gift_Request_Deadline__c': null,
  'FGM_Portal__NumberofLocations__c': null,
  'FGM_Portal__SLAExpirationDate__c': null,
  'FGM_Portal__SLASerialNumber__c': null,
  'FGM_Portal__SLA__c': null,
  'FGM_Portal__TestLookupUser__c': null,
  'FGM_Portal__TestRichText__c': null,
  'FGM_Portal__Testmultiselect__c': null,
  'FGM_Portal__UpsellOpportunity__c': null,
  'FGM_Portal__Number_of_Requests__c': 0,
  'Organisation_History__c': null,
  'Other_used_name__c': null,
  'Acronym__c': null,
  'Email__c': null,
  'Demography__c': null,
  'Service_belt__c': null,
  'Sector__c': null,
  'Service_Locations__c': null,
  'Address__c': null,
  'Mission_Statement__c': null,
  'Geographic_area_of_Operation__c': null,
  'Total_yearly_budget__c': null,
  'Services_provided__c': null,
  'Account_Type__c': null,
  'Main_funding_sources__c': null,
  'Number_of_buildings__c': null,
  'Housing_units_managed_by_Organisation__c': null,
  'First_Signer__c': null,
  'Facebook__c': null,
  'LinkedIn__c': null,
  'Other__c': null,
  'Is_a_member_of_an_Association_Network__c': null,
  'Association_Network_Name__c': null,
  'Other_Demographic__c': null,
  'Number_of_tenants_living_in_your_buildin__c': null,
  'Second_Signer__c': null,
  'Owner_Portal_User__c': null,
  'Is_Non_Profit_Housing__c': false
}
*/

let mapFields = {
  direction: 'in',
  Id: 'id',
  Name: 'organisationsName',
  Other_used_name__c: 'otherName',
  Facebook__c: 'facebook',
  Website: 'website',
  BillingCity: 'City',
  BillingState: 'province',
  BillingStreet: 'street',
  BillingPostalCode: 'postalCode',
  Organisation_History__c: 'organisationHistory',
  Geographic_area_of_Operation__c: 'geographicArea',
  Services_provided__c: 'services',
  Is_a_member_of_an_Association_Network__c: 'memberOfAsocNetw',
  Association_Network_Name__c: 'AsocNetwName',
  Account_Type__c: {
    key: 'organisationType',
    in: opp => opp.Account_Type__c ? opp.Account_Type__c.split(';') : [],
    out: res => res.organisationType.join(';')
  },
  Number_of_buildings__c: 'buildings',
  Acronym__c: 'acronym',
  Email__c: 'email',
  LinkedIn__c: 'linkedIn',
  NumberOfEmployees: 'employees',
  Total_yearly_budget__c: 'yearlyBudget',
  // numberOfMembers: '0'// ASK,
  Main_funding_sources__c: 'mainFundingSource',
  Number_of_tenants_living_in_your_buildin__c: 'numberOfTenants',
  Housing_units_managed_by_Organisation__c: 'units',
  Mission_Statement__c: 'missionStatement',
  Demography__c: {
    key: 'typeOfPopulation',
    in: opp => opp.Demography__c ? opp.Demography__c.split(';') : [],
    out: res => res.typeOfPopulation.join(';')
  },
}

const reverseMapFields = reverseMap(mapFields)
//isPartnerSuperUser
//

// associatedOrganisations,
//
export const getAccount = (id) => {
  let conn = SFAuthService.getConnection()
  if (conn) {
    return conn.sobject('Account').retrieve(id)
  } else {
    return Promise.reject(NO_USER)
  }
}

export const saveAccount = (value) => {
  console.log('save Account', value)
  let conn = SFAuthService.getConnection()
  if (value.Id) {
    return conn.sobject('Account').update(value)
  } else {
    return conn.sobject('Account').create(value)
  }
}

export const saveAccountParsed = (value) => {
  return saveAccount(mapSFToForm(reverseMapFields, value))
}

export const getAccountParsed = (id) => {
  return getAccount(id).then(user => mapSFToForm(mapFields, user))
}

export const getAssociatedOrganisations = (user) => {
  console.log('getAssociatedOrganisations', user)
  if (user) {
    return getAccountParsed(user.associatedOrganizations)
  } else {
    return Promise.reject(NO_USER)
  }
}


