const Layout1Settings = {
  leftSidebar: {
    show: true,
    mode: 'full', // full, close, compact, mobile,
    theme: 'slateDark1', // View all valid theme colors inside EgretTheme/themeColors.js
    bgOpacity: 0.1, // 0 ~ 1
    bgImgURL: '/assets/images/sidebar/sidebar-bg-chtc.png',
    bgStyle: {backgroundSize: 'cover'}
  },
  topbar: {
    show: true,
    fixed: true,
    theme: 'white' // View all valid theme colors inside EgretTheme/themeColors.js
  },
  secondarySidebar: {
    show: false
  }

}

export default Layout1Settings
