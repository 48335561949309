import { I18nProvider } from '@lingui/react'
import React, { useEffect } from 'react'
import catalogEn from 'locales/en/messages.js'
import catalogFr from 'locales/fr/messages.js'
import { connect } from 'react-redux'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import 'moment/locale/fr'

/**
 * Created by Michał Stawski on 20.09.2020.
 */

const catalogs = {
  en: catalogEn,
  en_US: catalogEn,
  fr: catalogFr,
  fr_CA: catalogFr,
}

export class UTCMomentUtils extends MomentUtils {
  parse (value, format) {
    return this.moment.utc(value, format, true)
  }

  date (value) {
    return this.moment.utc(value)
  }
}

const I18nConnectedProvider = ({ language, children }) => {
  useEffect(() => {
    moment.locale(language)
  }, [language])

  return <I18nProvider language={language} catalogs={catalogs}>
    <MuiPickersUtilsProvider
      utils={UTCMomentUtils}
      libInstance={moment}
      // locale?
      locale={language}
    >
      {children}
    </MuiPickersUtilsProvider>
  </I18nProvider>
}

const mapStateToProps = (state) => ({
  language: state.user.language,
})
export default connect(mapStateToProps)(I18nConnectedProvider)

