import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import { setUserData } from '../redux/actions/UserActions'
import localStorageService from '../services/localStorageService'
import firebaseAuthService from '../services/firebase/firebaseAuthService'
import sfAuthService from '../services/sfAuth/SFAuthService'
import { userLoggedIn, userLoggedOut } from '../redux/actions/LoginActions'
import { withRouter } from 'react-router'

class Auth extends Component {
  state = {}

  constructor (props) {
    super(props)

    let user = localStorageService.getItem('auth_user')
    this.props.setUserData(user)

    // this.checkJwtAuth();
    // this.checkFirebaseAuth();

    this.checkSfAuth(user)
  }

  // checkJwtAuth = () => {
  //   jwtAuthService.loginWithToken().then(user => {
  //     this.props.setUserData(user)
  //   })
  // }

  checkSfAuth = user => {
    let { location, history, login } = this.props
    let sentState = {
      redirect_uri: location.pathname
    }
    console.log('match', location, login, user, user && user.access_token)
    // TODO check user
    if (login.success) {
      console.log('user logged in')
    } else if (user && user.access_token && user.userId) {
      console.log('read user saved', user)
      sfAuthService
        .checkLogin(user)
        .then(
          ({ user, state }) => {
            this.props.setUserData(user)
            sfAuthService.setUser(user)
            this.props.userLoggedIn()
          },
          e => {
            console.error('user check failed', e, user)
            sfAuthService.setUser(null)
            this.props.userLoggedOut()
            sfAuthService.doLogin(sentState)
          }
        )
        .catch(e => {
          console.error('cant login from stored user', e, login)
          sfAuthService.setUser(null)
          this.props.userLoggedOut()
          sfAuthService.doLogin(sentState)
        })
    } else {
      sfAuthService
        .parseToken()
        .then(({ user, state }) => {
          this.props.setUserData(user)
          this.props.userLoggedIn()
          try {
            console.log('redirect', state)
            history.push({
              pathname: state.redirect_uri
            })
          } catch (e) {
            console.error("can't redirect going to root", e, user, state)
            // window.location = '/'
          }
        })
        .catch(e => {
          console.error('cant login', e, login)
          sfAuthService.doLogin(sentState)
        })
    }
  }

  checkFirebaseAuth = () => {
    firebaseAuthService.checkAuthStatus(user => {
      if (user) {
        console.log(user.uid)
        console.log(user.email)
        console.log(user.emailVerified)
      } else {
        console.log('not logged in')
      }
    })
  }

  render () {
    const { children } = this.props
    return <Fragment>{children}</Fragment>
  }
}

const mapStateToProps = state => ({
  setUserData: PropTypes.func.isRequired,
  login: state.login,
  user: state.user
})

export default connect(mapStateToProps, {
  setUserData,
  userLoggedIn,
  userLoggedOut
})(withRouter(Auth))
