/**
 * Created by Michał Stawski on 20.05.2020.
 */
const PROD = 'prod'
const SCRIPT = 'script'
const TESTING = 'testing'
const HOTFIX = 'hotfix'

// TODO move id and secret  to env vars for deploy
const configs = {
  [PROD]: {
    instanceUrl: 'https://chtc-ctlc.my.salesforce.com',
    loginUrl: 'https://chtc.force.com/APIConnectionPortal',
    clientId: '3MVG9nkapUnZB56EDSefar0nDpAV8iiLs5xo8zilpKveJAWRPx3Q0t4xShdTGu.B8hf85N.SIM7qHytNhoKP2',
    redirectUri: 'https://community.centre.support',
  },
  // same connection as prod just with new code
  [HOTFIX]: {
    instanceUrl: 'https://chtc-ctlc.my.salesforce.com',
    loginUrl: 'https://chtc.force.com/APIConnectionPortal',
    clientId: '3MVG9nkapUnZB56EDSefar0nDpAV8iiLs5xo8zilpKveJAWRPx3Q0t4xShdTGu.B8hf85N.SIM7qHytNhoKP2',
    redirectUri: 'https://hotfix.centre.support',
  },
  [SCRIPT]: {
    instanceUrl: 'https://chtc-ctlc--script.my.salesforce.com',
    loginUrl: 'https://script-chtc.cs148.force.com/APIConnectionPortal',
    clientId: '3MVG9SUK8FGR3O.tJC0xL_RuyKrAj1GPEpQx.beFIT.5PLDTerGvqdjF_FNQIKH1.nCMRn9M91eLCdn6L8Ry3',
    redirectUri: 'https://dev.centre.support',
  },

  [TESTING]: {
    instanceUrl: 'https://chtc-ctlc--testing.my.salesforce.com',
    loginUrl: 'https://testing-chtc.cs99.force.com/APIConnectionPortal',
    clientId: '3MVG9rIycKjurncZx12hVuPLVUlLX260T6VHDh9HhDKuOhTQgvZQPtFML6tQxazTc2B0LBYz6DRTU5NFxscUh',
    redirectUri: 'https://testing.centre.support',
  },
}

let current_config

current_config = configs[process.env.REACT_APP_ENVIROMENT]

if (!current_config) {
  current_config = configs[PROD]
}

export const isSandbox = () => {
  return process.env.REACT_APP_ENVIROMENT !== PROD
}

const sfOauthConfig = {
  scope: 'web api id',
  response_type: 'token',
  instanceUrl: current_config.instanceUrl,

  // you can change loginUrl to connect to sandbox or prerelease env.
  oauth2: {
    loginUrl: current_config.loginUrl,
    clientId: current_config.clientId,
    clientSecret: current_config.clientSecret,
    redirectUri: process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000'
      : current_config.redirectUri,
  },
}

export default sfOauthConfig
